<template>
  <div>
    <b-form-group id="input-group-student_name">
      <label for="input-student_name">Nama Mahasiswa:</label>
      <b-form-input
        id="input-student_name"
        v-model="form.student_name"
        placeholder="Nama Mahasiswa"
        readonly
      ></b-form-input>
      <small class="text-danger">{{ error.student_name }}</small>
    </b-form-group>

    <b-form-group id="input-group-registration_number">
      <label for="input-registration_number">NIM:</label>
      <b-form-input
        id="input-registration_number"
        v-model="form.registration_number"
        placeholder="NIM"
        readonly
      ></b-form-input>
      <small class="text-danger">{{ error.registration_number }}</small>
    </b-form-group>

    <b-form-group
      id="input-group-semester"
      label="Semester:"
      label-for="input-semester"
    >
      <treeselect
        v-model="form.semester_id"
        :multiple="false"
        placeholder="Pilih Semester"
        :options="semesters"
      />
      <small class="text-danger">{{ error.semester_id }}</small>
    </b-form-group>

    <b-form-group
      id="input-group-study_program_id"
      label="Program Studi:"
      label-for="input-study_program_id"
    >
      <treeselect
        v-model="form.study_program_id"
        :multiple="false"
        placeholder="Pilih Program Studi"
        :options="study_programs"
        disbled
      />
      <small class="text-danger">{{ error.study_program_id }}</small>
    </b-form-group>

    <b-form-group
      id="input-group-faculty_id"
      label="Fakultas:"
      label-for="input-faculty_id"
    >
      <treeselect
        v-model="form.faculty_id"
        :multiple="false"
        placeholder="Pilih Fakultas"
        :options="faculties"
        disabled
      />
      <small class="text-danger">{{ error.faculty_id }}</small>
    </b-form-group>

    <b-form-group id="input-group-phone">
      <label for="input-phone">No. Telp / WA:</label>
      <b-form-input
        id="input-phone"
        v-model="form.phone"
        placeholder="No. Telp / WA"
        readonly
      ></b-form-input>
      <small class="text-danger">{{ error.phone }}</small>
    </b-form-group>

    <b-form-group id="input-group-email">
      <label for="input-email">Alamat Email:</label>
      <b-form-input
        id="input-email"
        v-model="form.email"
        placeholder="Alamat Email"
        readonly
      ></b-form-input>
      <small class="text-danger">{{ error.email }}</small>
    </b-form-group>

    <b-form-group id="input-group-address">
      <label for="input-address">Alamat:</label>
      <b-form-textarea
        id="input-address"
        v-model="form.address"
        placeholder="Alamat"
        rows="4"
        max-rows="8"
      ></b-form-textarea>
      <small class="text-danger">{{ error.address }}</small>
    </b-form-group>

    <slot name="main"></slot>

    <b-form-group id="input-group-description">
      <label for="input-description">Keterangan:</label>
      <b-form-textarea
        id="input-description"
        v-model="form.description"
        placeholder="Uraikan Keperluan Anda"
        rows="4"
        max-rows="8"
      ></b-form-textarea>
      <small class="text-danger">{{ error.description }}</small>
    </b-form-group>

    <!-- <b-form-group id="input-group-photo">
      <label class="col-xl-3 col-lg-5 col-form-label pl-0 pt-0"
        >Upload Tanda Tangan (PNG)</label
      >
      <div class="col-lg-9 col-xl-6 pl-0 pt-0">
        <div class="image-input image-input-outline" id="kt_profile_avatar">
          <div class="image-input-wrapper">
            <img :src="photo" alt="" />
          </div>
          <label
            class="
              btn
              btn-xs
              btn-icon
              btn-circle
              btn-white
              btn-hover-text-primary
              btn-shadow
            "
            data-action="change"
            data-toggle="tooltip"
            title=""
            data-original-title="Change avatar"
          >
            <i class="fa fa-pen icon-sm text-muted"></i>
            <input
              type="file"
              name="profile_avatar"
              accept=".png"
              @change="onFileChange($event)"
            />
            <input type="hidden" name="profile_avatar_remove" />
          </label>
          <span
            class="
              btn
              btn-xs
              btn-icon
              btn-circle
              btn-white
              btn-hover-text-primary
              btn-shadow
            "
            data-action="cancel"
            data-toggle="tooltip"
            title="Cancel avatar"
          >
            <i class="ki ki-bold-close icon-xs text-muted"></i>
          </span>
          <span
            class="
              btn
              btn-xs
              btn-icon
              btn-circle
              btn-white
              btn-hover-text-primary
              btn-shadow
            "
            data-action="remove"
            data-toggle="tooltip"
            title="Remove avatar"
            @click="current_photo = null"
          >
            <i class="ki ki-bold-close icon-xs text-muted"></i>
          </span>
        </div>
      </div>
      <small class="text-danger">{{ error.sign_image }}</small>
    </b-form-group> -->
  </div>
  <!--end: Wizard Step 1-->
</template>

<script>
import module from "@/core/modules/CrudModule.js";

export default {
  props: {
    form: Object,
    route: String,
    title: String,
    purpose: String,
    currentPhoto: String,
  },
  data() {
    return {
      formData: new FormData(),
      error: {
        semester_id: "",
        sign_image: "",
      },
      current_photo: "/img/default/blank.jpg",
      default_photo: "/img/default/blank.jpg",
      semesters: [],
      study_programs: [],
      faculties: [],
    };
  },
  methods: {
    async getSemesterActive() {
      let response = await module.get("api/semesters/now");
      // If Data Not Found
      if (response != null) {
        this.form.semester_id = response.id;
      }
    },
    async getSemesterOption() {
      let response = await module.setTreeSelect("api/semester-groups");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.semesters = response.data;
        this.semesters.unshift({
          label: "Pilih Semester",
          id: "",
          isDisabled: true,
        });
      }
    },
    async getMajorOption() {
      let response = await module.setTreeSelect("api/majors");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.study_programs = response.data;
        this.study_programs.unshift({
          label: "Pilih Program Studi",
          id: "",
          isDisabled: true,
        });
      }
    },
    async getFacultyOption() {
      let response = await module.setTreeSelect("api/faculties");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.faculties = response.data;
        this.faculties.unshift({
          label: "Pilih Fakultas",
          id: "",
          isDisabled: true,
        });
      }
    },
    onFileChange(e) {
      const file = e.target.files[0];

      if (typeof FileReader === "function") {
        const reader = new FileReader();

        this.form.sign_image = e.target.files[0];

        reader.onload = (event) => {
          this.current_photo = event.target.result;
        };
        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
    },
    onRemove() {
      this.current_photo = null;
      this.form.photo = "";
    },
    validationCheck() {
      let status = true;
      // if (
      //   this.form.hasOwnProperty("interest") == false ||
      //   this.form.interest == ""
      // ) {
      //   this.error.interest = "Kolom kepeminatan tidak boleh kosong";
      //   status = false;
      // } else {
      //   this.error.interest = "";
      // }
      // if (
      //   this.form.hasOwnProperty("institution1") == false ||
      //   this.form.institution1 == ""
      // ) {
      //   this.error.institution1 = "Kolom instansi 1 tidak boleh kosong";
      //   status = false;
      // } else {
      //   this.error.institution1 = "";
      // }
      // if (
      //   this.form.hasOwnProperty("institution_address1") == false ||
      //   this.form.institution_address1 == ""
      // ) {
      //   this.error.institution_address1 =
      //     "Kolom alamat instansi 1 tidak boleh kosong";
      //   status = false;
      // } else {
      //   this.error.institution_address1 = "";
      // }
      // if (
      //   this.form.hasOwnProperty("institution2") == false ||
      //   this.form.institution2 == ""
      // ) {
      //   this.error.institution2 = "Kolom instansi 2 tidak boleh kosong";
      //   status = false;
      // } else {
      //   this.error.institution2 = "";
      // }
      // if (
      //   this.form.hasOwnProperty("institution_address2") == false ||
      //   this.form.institution_address2 == ""
      // ) {
      //   this.error.institution_address2 =
      //     "Kolom instansi 2 tidak boleh kosong";
      //   status = false;
      // } else {
      //   this.error.institution_address2 = "";
      // }
      // if (this.purpose == "add") {
      //   if (
      //     this.form.hasOwnProperty("sign_image") == false ||
      //     this.form.sign_image == ""
      //   ) {
      //     this.error.sign_image = "Gambat TTD belum dipilih";
      //     status = false;
      //   } else {
      //     this.error.sign_image = "";
      //   }
      // }

      return status;
    },
  },
  mounted() {
    this.getSemesterOption();
    this.getMajorOption();
    this.getFacultyOption();
    if (this.purpose != "edit") {
      this.getSemesterActive();
    }

    this.$root.$on("validationImageFalse", () => {
      this.error.sign_image = "";
    });
    this.$root.$on("validationImageTrue", () => {
      this.error.sign_image = "Gambar TTD belum dipilih";
      console.log("receive emit");
    });
  },
  computed: {
    photo() {
      return this.current_photo == null
        ? this.default_photo
        : this.current_photo;
    },
  },
  watch: {
    form: function (newVal, oldVal) {
      if (this.purpose == "edit") {
        // this.form = this.form;
        this.form.description = this.form.description;
        this.form.semester_id = this.form.semester_id;
        this.current_photo = this.form.sign;
        this.default_photo = this.form.sign;
      }
    },
  },
};
</script>

<style scoped>
.image-input-wrapper {
  width: 180px !important;
  height: 180px !important;
}

.image-input-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
</style>